import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

import EnhancedCard from 'app/components/ui/EnhancedCard';
import CardHeader from 'app/components/ui/EnhancedCard/CardHeader';
import { REFLECT_360_WELCOME_CARD_SEEN } from 'app/constants';
import { SessionStorageHelper } from 'app/helpers';

interface Reflect360WelcomeCardProps {
  firstName: string;
}

const Reflect360WelcomeCard: React.FC<Reflect360WelcomeCardProps> = ({ firstName }) => {
  const [isCardDismissed, setIsCardDismissed] = React.useState(false);

  const dismissButton = (
    <CloseOutlined
      onClick={() => {
        SessionStorageHelper.set(REFLECT_360_WELCOME_CARD_SEEN, true);
        setIsCardDismissed(true);
      }}
    />
  );

  const isDismissed = isCardDismissed || SessionStorageHelper.get(REFLECT_360_WELCOME_CARD_SEEN);

  if (isDismissed) {
    return null;
  }

  return (
    <div className="reflect-360-welcome-card">
      <EnhancedCard className="statement-card">
        <CardHeader
          className="card-header"
          heading={`${firstName}, welcome to your Reflect 360 report!`}
          image={<img className="header-img" src={'/images/coach-img.png'} />}
          icon={dismissButton}
          iconClassName="dismiss-icon"
        />

        <div className="card-body">
          <span>
            <strong>The goal of this report is growth, not evaluation.</strong> At Valence, we're
            big believers in honest feedback being the best driver of development, and you're about
            to see both quantitative and qualitative feedback from others.
            <strong> Our recommendation:</strong> start by celebrating what's going well for you and
            what others feel are your strengths, before moving onto improvements. As you explore
            your results, try to focus on where you can leverage your strengths to fuel your
            opportunity areas.
          </span>
        </div>
      </EnhancedCard>
    </div>
  );
};

export default Reflect360WelcomeCard;
