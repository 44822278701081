import { action, computed, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem, ModelList } from 'app/models';
import { ExerciseTypeModel } from 'app/models/ExerciseTypeModel';

import Store from './Store';

export class ExerciseTypeStore extends Store<ExerciseTypeModel> {
  @observable public exerciseTypes = new ModelList<ExerciseTypeModel>(ExerciseTypeModel);
  @observable public exerciseType = new ModelItem<ExerciseTypeModel>(ExerciseTypeModel);
  @observable public exerciseTypesWithDetails = new ModelList<ExerciseTypeModel>(ExerciseTypeModel);

  constructor() {
    super();
    ExerciseTypeModel._store = this;
  }

  @computed
  public get isLoadingExerciseTypes(): boolean {
    return this.exerciseTypes.loading;
  }

  @computed
  public get isLoadingExerciseType(): boolean {
    return this.exerciseType.loading;
  }

  async getTeamExerciseType(teamId: number, exerciseTypeId: number): Promise<void> {
    const exerciseType = this.exerciseTypesWithDetails.items.find(
      (exerciseType) => exerciseType.id === exerciseTypeId
    );

    if (exerciseType) {
      this.exerciseType.setItem(exerciseType);
      return;
    }

    const url = ServerRouteHelper.api.teams.exerciseTypes(teamId, exerciseTypeId);
    await this.exerciseType.load(url);
    this.exerciseTypesWithDetails.appendUniqueItems([this.exerciseType.item]);
  }

  @action
  async loadExerciseTypes(): Promise<void> {
    const url = ServerRouteHelper.api.exerciseTypes.list();
    await this.exerciseTypes.load(url);
  }

  @action
  async loadOrgExerciseTypes(orgId: number): Promise<void> {
    const url = ServerRouteHelper.api.exerciseTypes.orgExerciseTypes(orgId);
    await this.exerciseTypes.load(url);
  }

  @action
  async loadTeamExerciseTypes(teamId: number, forceRefresh?: boolean): Promise<void> {
    const url = ServerRouteHelper.api.exerciseTypes.teamExerciseTypes(teamId);
    await this.exerciseTypes.load(url, undefined, { forceRefresh });
  }

  async loadExerciseTypeBySecretCode(code: string): Promise<void> {
    const url = ServerRouteHelper.api.exercises.showBySecretCode(code);

    return await this.exerciseType.load(url);
  }
}

export default ExerciseTypeStore;
